'use client'

import { addDDError } from '@capabilities/actions'
import type { ComponentProps } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { CNFErrorBoundaryFallback } from './CNFErrorBoundaryFallback'

type CNFErrorBoundaryProps = Omit<
  ComponentProps<typeof ErrorBoundary>,
  'fallbackRender'
> & {
  fallbackRender?: ComponentProps<typeof ErrorBoundary>['fallbackRender']
}

export function CNFErrorBoundary({
  children,
  onError = addDDError,
  fallbackRender = () => <CNFErrorBoundaryFallback />,
}: CNFErrorBoundaryProps) {
  return (
    <ErrorBoundary fallbackRender={fallbackRender} onError={onError}>
      {children}
    </ErrorBoundary>
  )
}
