import { useErrorBoundary } from 'react-error-boundary'
import { RefreshCw } from 'react-feather'
import { Button, Text, View } from 'reshaped'

export function CNFErrorBoundaryFallback() {
  const { resetBoundary } = useErrorBoundary()

  return (
    <View
      align={'center'}
      textAlign="center"
      borderRadius={'large'}
      paddingInline={4}
      paddingBlock={8}
      gap={6}
    >
      <Text variant={'title-4'} color="neutral">
        Something went wrong
      </Text>
      <Text variant={'featured-3'} color="neutral-faded">
        There was an unexpected error, please check your connection and try again.
      </Text>

      <Button
        size={'large'}
        attributes={{ style: { maxWidth: '168px' } }}
        fullWidth
        color="neutral"
        variant="outline"
        onClick={resetBoundary}
      >
        <RefreshCw width={12} />
        Refresh
      </Button>
    </View>
  )
}
